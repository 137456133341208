import { FC, MouseEventHandler, PropsWithChildren } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import styles from './index.module.scss'
import { LoadingIndicator } from '../LoadingIndicator'
import { sanitizeUrl } from '../../../utils'

type Props = {
  href?: string
  componentType?: 'button' | 'a'
  styleType?: 'default' | 'outline' | 'blue' | 'blueFrame'
  color?: 'default' | 'blue' | 'gray' | 'darkBlue' | 'lightGray'
  isTransparentBackground?: boolean
  isBorder?: boolean
  buttonSize?: 'small' | 'medium' | 'large' | 'slim'
  fontSize?: 'small' | 'medium' | 'large'
  buttonType?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  anchorTarget?: '_self' | '_blank'
  isLoading?: boolean
}

export const Button: FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  href = '',
  styleType = 'default',
  color = 'default',
  isTransparentBackground = false,
  isBorder = false,
  buttonSize = 'medium',
  fontSize = 'large',
  componentType = 'button',
  buttonType = 'button',
  disabled = false,
  anchorTarget = '_self',
  isLoading = false,
}) => {
  const className = classNames(
    styles.container,
    {
      [styles.defaultContainer]: styleType === 'default',
      [styles.outlineContainer]: styleType === 'outline',
      [styles.blueContainer]: styleType === 'blue',
      [styles.blueFrameContainer]: styleType === 'blueFrame',
      [styles.gray]: color === 'gray',
      [styles.blue]: color === 'blue',
      [styles.darkBlue]: color === 'darkBlue',
      [styles.lightGray]: color === 'lightGray',
    },
    {
      [styles.transparentBackground]: isTransparentBackground,
    },
    {
      [styles.border]: isBorder,
    },
    {
      [styles.smallButton]: buttonSize === 'small',
      [styles.mediumButton]: buttonSize === 'medium',
      [styles.largeButton]: buttonSize === 'large',
      [styles.slimButton]: buttonSize === 'slim',
    },
    {
      [styles.largeButton__largeFont]: buttonSize === 'large' && fontSize === 'large',
    },
    {
      [styles.mediumFont]: fontSize === 'medium',
    },
    {
      [styles.disabledButton]: disabled && componentType === 'button',
    },
    {
      [styles.disabledLink]: disabled && componentType === 'a',
    },
    {
      [styles.loading]: isLoading && componentType === 'button', // a tag にはインジケーターを表示しない
    }
  )

  if (componentType === 'a') {
    return (
      <Link href={sanitizeUrl(href)}>
        <a className={className} target={anchorTarget}>
          {children}
        </a>
      </Link>
    )
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={buttonType} className={className} onClick={onClick} disabled={disabled}>
      {children}
      {isLoading && (
        <div className={styles.loadingContainer}>
          <LoadingIndicator size='small' color={color === 'lightGray' ? 'black' : 'white'} />
        </div>
      )}
    </button>
  )
}
