import { FC } from 'react'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'

type Props = {
  title: string
  isTitleWithSuffix?: boolean
  description?: string
  url?: string
  image?: string
  ogType?: string
  twitterCardType?: string
}

export const MyHead: FC<Props> = ({
  title,
  isTitleWithSuffix = true,
  description = '',
  url = process.browser ? window.location.href : '',
  image = '',
  ogType = 'website',
  twitterCardType = 'summary_large_image',
}) => {
  const { t } = useTranslation(['global'])
  const formattedTitle = isTitleWithSuffix ? `${title} - AQA` : title
  let ogImage = image
  if (!ogImage) {
    ogImage = `${process.env.NEXT_PUBLIC_APP_BASE_URL}/images/common/og_image.png`
  }
  let fixDescription = description
  if (!fixDescription) {
    fixDescription = t(
      'The AQA connects members of Web3 startups, investors and experts through sharing knowledge. It promotes the vitalisation of the Web3 market worldwide.'
    )
  }

  return (
    <Head>
      <title>{formattedTitle}</title>
      <meta name='description' content={fixDescription} />
      <meta property='og:title' content={formattedTitle} />
      <meta property='og:description' content={fixDescription} />
      <meta property='og:type' content={ogType} />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={ogImage} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
      <meta property='og:site_name' content='AQA' />
      <meta name='twitter:card' content={twitterCardType} />
      <meta name='twitter:domain' content='aqa3.io' />
      <meta name='twitter:site' content='@AQA314' />
      <meta name='twitter:url' content={ogImage} />
      <meta name='twitter:title' content={formattedTitle} />
      <meta name='twitter:description' content={fixDescription} />
      <meta name='twitter:image' content={ogImage} />
      <link rel='canonical' href={url} />
      <link rel='stylesheet' href='https://use.typekit.net/dgm2zbq.css' />
    </Head>
  )
}
