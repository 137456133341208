import { FC } from 'react'
import { PageTransition } from 'next-page-transitions'
import { useRouter } from 'next/router'
import { ToastManager } from '@components/organisms/ToastManager'
import { MobileNavigationHeader } from '@/components/organisms/MobileNavigationHeader'
import { VerticalNavigationMenuGroup } from '@/components/organisms/VerticalNavigationMenuGroup'
import { SignUpPromotionPopup } from '@/components/organisms/SignUpPromotionPopup'
import { UserInvitationModal } from '@/components/molecules/UserInvitationModal'
import { ForumModal } from '@/components/organisms/ForumModal'
import { getIsActive, useStores } from '@/utils'
import styles from './index.module.scss'

export const VerticalNavDefaultPageTemplate: FC = ({ children }) => {
  const router = useRouter()
  const isQuestionPage = getIsActive(['/forum/[question_slug]'], router, true, true)
  const { ui } = useStores()
  return (
    <div className={styles.container}>
      {ui.isMobile && <MobileNavigationHeader />}
      <main className={styles.main}>
        <div className='container'>
          <div className={styles.navigation}>
            <VerticalNavigationMenuGroup />
          </div>
          <PageTransition timeout={300} classNames='page-transition'>
            <PageTransitionInner key={router.route}>
              <div className={styles.content}>{children}</div>
            </PageTransitionInner>
          </PageTransition>
        </div>
      </main>
      <UserInvitationModal />
      {!isQuestionPage && <ForumModal />}
      <SignUpPromotionPopup />
      <ToastManager />
    </div>
  )
}

// PageTransitionに直でHTMLとなる要素を渡すとエラーを吐くので、そのエラーを潰す用に噛ませるComponent
const PageTransitionInner: FC = ({ children }) => {
  return <>{children}</>
}
