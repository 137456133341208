import { observer } from 'mobx-react'
import { FC } from 'react'
import { useStores } from '@/utils'
import { CloseButton } from '@components/atoms/CloseButton'
import { SignInAndJoinBtnGroup } from '@components/molecules/SignInAndJoinBtnGroup'
import styles from './index.module.scss'

type Props = {
  type?: 'jobSeeker' | 'investor' | 'startup'
}

export const SignUpPromotionPopupContent: FC<Props> = observer(({ type }) => {
  const { ui } = useStores()

  const toggleModal = () => {
    ui.toggleSignUpModal()
  }

  let [title, text] = ['Enjoy Forum to Earn!', 'Sign up AQA to exchange Web3 information and ideas.']
  if (type === 'jobSeeker') {
    title = 'Sign up and appeal to startups/DAOs!'
    text = 'Sign up for AQA and fill out your profile to expand your chances of getting projects!'
  }
  if (type === 'investor') {
    title = 'Sign up and appeal to startups/DAOs!'
    text = 'Sign up for AQA and fill out your profile to expand your chances of sourcing investments!'
  }
  if (type === 'startup') {
    title = 'Sign up and appeal to token helders!'
    text = 'Sign up for AQA and fill out your profile to expand your chances of getting funding!'
  }

  return (
    <>
      <div className={styles.heading}>
        <div className={styles.title}>{title}</div>
        <div className={styles.closeButton}>
          <CloseButton onClick={toggleModal} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.text}>{text}</div>
      </div>
      <SignInAndJoinBtnGroup />
    </>
  )
})
