import { FC, useState } from 'react'
import classNames from 'classnames'
import AnimateHeight from 'react-animate-height'
import styles from './index.module.scss'

export const ShowButtonForMobile: FC = ({ children }) => {
  const [isOpenDropDown, setIsOpenDropDown] = useState(false)
  const toggleDropDown = () => {
    setIsOpenDropDown(!isOpenDropDown)
  }

  return (
    <>
      <div className={styles.desktop}>{children}</div>
      <div className={styles.mobile}>
        <div role='button' tabIndex={0} className={styles.content} onMouseDown={() => toggleDropDown()}>
          <div className={styles.title}>
            <div className={styles.label}>{isOpenDropDown ? 'Show less' : 'Show more'}</div>
            <span className={classNames(styles.arrowIcon, { [styles.active]: isOpenDropDown })} />
          </div>
        </div>
        <AnimateHeight duration={500} height={isOpenDropDown ? 'auto' : 0} easing='ease'>
          <div className={styles.child}>{children}</div>
        </AnimateHeight>
      </div>
    </>
  )
}
